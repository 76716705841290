<template>
  <!--====== PRICING PART START ======-->
  <section id="pricing" class="pricing-section">
    <div class="container mt-4">
      <div class="section-title text-center mt-16">
        <h1>Planes y precios</h1>
        <p class="mt-6 mb-4">
          Acist te ayuda a gestionar tu negocio de principio a fin.
        </p>
        <br />
        <br />
      </div>
      <div class="text-center">
        <v-btn-toggle
          v-model="toggle_exclusive"
          mandatory
          rounded
          class="mb-16"
          color="primary"
        >
          <v-btn @click="(business = true) && (consultants = false)"><span class="mx-4"> Para Empresas</span></v-btn>
          <v-btn @click="(consultants = true) && (business = false)"><span class="mx-4"> Para Consultores</span></v-btn>
          <!-- <v-btn>
            <v-icon></v-icon>
          </v-btn> -->
        </v-btn-toggle>
      </div>

      <div v-if="business" class="outer-box">
        <v-row>
          <!-- FREE PLAN -->
          <PricingCard :plan="freePlan" />

          <!-- BASIC PLAN -->
          <PricingCard :plan="basicPlan" />

          <!-- PRO PLAN -->
          <PricingCard :plan="proPlan" />
        </v-row>
      </div>
      <div v-if="consultants" class="outer-box">
        <v-row>
          <!-- FREE PLAN -->
          <PricingCard :plan="freePlanConsultants" />

          <!-- BASIC PLAN -->
          <PricingCard :plan="basicPlanConsultants" />

          <!-- PRO PLAN -->
          <PricingCard :plan="proPlanConsultants" />
        </v-row>
      </div>
    </div>
  </section>

  <!--====== PRICING PART ENDS ======-->
</template>

<script>
import PricingCard from "./elements/PricingCard.vue";

export default {
  name: "Pricing",
  components: {
    PricingCard,
  },
  data: () => ({
    business: true,
    consultants: false,
    freePlan: {
      name: "Free",
      imgIcon: "basic",
      price: "0",
      functionalities: [
        "Point 1",
        "Point 2",
        "Point 3",
        "Point 4",
        "Point 5",
        "Point 6",
      ],
      bonus: ["Point 1", "Point 2", "Point 3"],
      link: "",
    },
    basicPlan: {
      name: "Basic",
      imgIcon: "pro",
      price: "70",
      functionalities: [
        "Point 1",
        "Point 2",
        "Point 3",
        "Point 4",
        "Point 5",
        "Point 6",
      ],
      bonus: ["Point 1", "Point 2", "Point 3"],
      link: "",
    },
    proPlan: {
      name: "Pro",
      imgIcon: "crown",
      price: "120",
      functionalities: [
        "Point 1",
        "Point 2",
        "Point 3",
        "Point 4",
        "Point 5",
        "Point 6",
      ],
      bonus: ["Point 1", "Point 2", "Point 3"],
      link: "",
    },
    freePlanConsultants: {
      name: "C-Free",
      imgIcon: "basic",
      price: "0",
      functionalities: [
        "Point 1",
        "Point 2",
        "Point 3",
        "Point 4",
        "Point 5",
        "Point 6",
      ],
      bonus: ["Point 1", "Point 2", "Point 3"],
      link: "",
    },
    basicPlanConsultants: {
      name: "C-Basic",
      imgIcon: "pro",
      price: "70",
      functionalities: [
        "Point 1",
        "Point 2",
        "Point 3",
        "Point 4",
        "Point 5",
        "Point 6",
      ],
      bonus: ["Point 1", "Point 2", "Point 3"],
      link: "",
    },
    proPlanConsultants: {
      name: "C-Pro",
      imgIcon: "crown",
      price: "120",
      functionalities: [
        "Point 1",
        "Point 2",
        "Point 3",
        "Point 4",
        "Point 5",
        "Point 6",
      ],
      bonus: ["Point 1", "Point 2", "Point 3"],
      link: "",
    },
  }),
};
</script>

<style scoped>
#gc-des {
  font-size: 13px;
}
.v-application .primary--text {
  color: crimson !important;
}
</style>
