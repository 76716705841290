<template>
  <v-container class="pa-0" fluid>
    <Pricing />
  </v-container>
</template>

<script>
  import Pricing from '../components/sections/Pricing.vue'

  export default {
    name: 'Prices',

    components: {
      Pricing
    },
  }
</script>