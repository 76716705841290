<template>
  <div class="pricing-block col-lg-4 col-md-6 col-sm-12">
    <div class="inner-box">
      <div class="icon-box">
        <div class="icon-outer">
          <i class="lni lni-grow">
            <img
              :src="require('@/assets/icons/'+plan.imgIcon+'.png')"
              alt=""
              class="mt-6"
              style="height: 64px"
            />
          </i>
        </div>
      </div>
      <div class="price-box">
        <h2>{{plan.name}}</h2>
        <h4 class="price">USD {{plan.price}}</h4>
        <h6 class="sbtl">por mes</h6>
        <div class="btn_buy">
          <v-btn class="mainButton my-4" rounded dark style="justify: center">
            Probar
          </v-btn>
        </div>
      </div>
      <hr />
      <ul class="features">
        <!-- Funcionalidades -->
        <br />
        <h4><b>Funcionalidades</b></h4>
        <br />
        <div v-for="item in plan.functionalities" :key="item">
            <li id="gc-des" class="true" >
            <v-icon color="blue" small>mdi-check-circle-outline </v-icon>
            {{item}}
            </li>
        </div>
        <!-- All plans includes -->
        <br />
        <h4><b>Incluye</b></h4>
        <br />
        <li id="gc-des" v-for="item in plan.bonus" :key="item">
          <v-icon color="orange" small>mdi-star </v-icon> {{item}}
        </li>
      </ul>
      <div class="btn_buy">
        <v-btn class="mainButton mt-14" rounded dark style="justify: center">
          Probar
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PricingCard',
  components: {
  },
  props: {plan: Array}
};
</script>

<style scoped>
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

a,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
  transition-timing-function: ease-in-out;
  -ms-transition-timing-function: ease-in-out;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  -ms-transition-duration: 0.2s;
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
/*--blog----*/

.sec-title {
  position: relative;
  margin-bottom: 70px;
}

.sec-title .title {
  position: relative;
  display: block;
  line-height: 1em;
  color: #ff8a01;
  font-weight: 500;
  background: rgb(247, 0, 104);
  background: -moz-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 25%,
    rgba(247, 0, 104, 1) 75%,
    rgba(68, 16, 102, 1) 100%
  );
  background: -webkit-linear-gradient(
    to left,
    rgba(247, 0, 104, 1) 0%,
    rgba(68, 16, 102, 1) 25%,
    rgba(247, 0, 104, 1) 75%,
    rgba(68, 16, 102, 1) 100%
  );
  background: linear-gradient(
    to left,
    rgba(247, 0, 104) 0%,
    rgba(68, 16, 102, 1) 25%,
    rgba(247, 0, 104, 1) 75%,
    rgba(68, 16, 102, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F70068', endColorstr='#441066',GradientType=1 );
  color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin-bottom: 15px;
}

.sec-title h2 {
  position: relative;
  display: inline-block;
  font-size: 48px;
  line-height: 1.2em;
  color: #1e1f36;
  font-weight: 700;
}

.sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 28px;
  color: #888888;
  margin-top: 30px;
}

.sec-title.light h2,
.sec-title.light .title {
  color: #ffffff;
  -webkit-text-fill-color: inherit;
}
.pricing-section {
  position: relative;
  padding: 0px 0 40px;
  overflow: hidden;
}
.pricing-section .outer-box {
  max-width: 1100px;
  margin: 0 auto;
}

.pricing-section .row {
  margin: 0 -30px;
}

.pricing-block {
  position: relative;
  padding: 0 20px;
  margin-bottom: 40px;
}

.pricing-block .inner-box {
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.08);
  padding: 0 0 30px;
  max-width: 390px;
  margin: 0 auto;
  border-bottom: 20px solid #ff4a4a;
}

.pricing-block .icon-box {
  position: relative;
  padding: 50px 30px 0;
  background-color: #e4e4e4;
  text-align: center;
}

.pricing-block .icon-box:before {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 75px;
  width: 100%;
  border-radius: 50% 50% 0 0;
  background-color: #ffffff;
  content: "";
}

.pricing-block .icon-box .icon-outer {
  position: relative;
  height: 150px;
  width: 150px;
  background-color: #ffffff;
  border-radius: 50%;
  margin: 0 auto;
  padding: 10px;
}

.pricing-block .icon-box i {
  position: relative;
  display: block;
  height: 130px;
  width: 130px;
  line-height: 120px;
  border: 5px solid #ff4a4a;
  border-radius: 50%;
  font-size: 50px;
  color: #ff4a4a;
  -webkit-transition: all 600ms ease;
  -ms-transition: all 600ms ease;
  -o-transition: all 600ms ease;
  -moz-transition: all 600ms ease;
  transition: all 600ms ease;
}

.pricing-block .inner-box:hover .icon-box i {
  transform: rotate(360deg);
}

.pricing-block .price-box {
  position: relative;
  text-align: center;
  padding: 10px 20px;
}

.pricing-block .title {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: #2e2e2e;
  padding: 20px 0px;
}

.pricing-block .price {
  display: block;
  font-size: 30px;
  color: #222222;
  font-weight: 700;
  color: #ff4a4a;
}

.pricing-block .price-f {
  display: block;
  font-size: 30px;
  color: #222222;
  font-weight: 700;
  color: #ff4a4a;
  margin-bottom: 48px;
}

.pricing-block .sbtl {
  display: block;
  color: #222222;
  font-weight: 700;
  color: #ff4a4a;
}

.features h6 {
  padding: 15% 0%;
}

.pricing-block .features {
  position: relative;
  max-width: 200px;
  margin: 0% 15% 0%;
}

.pricing-block .features li {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 30px;
  color: #848484;
  font-weight: 500;
  border-bottom: 1px dashed #dddddd;
}

.pricing-block .features li i {
  padding-right: 5px;
}

.pricing-block .features li i.check {
  color: #ff4a4a;
}

.pricing-block .features li i.block {
  color: red;
}

.pricing-block .features li:before {
  position: absolute;
  left: 0;
  top: 50%;
  font-size: 16px;
  color: #ff4a4a;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  margin-top: -8px;
}

.pricing-block .features li a {
  color: #848484;
}

.pricing-block .features li:last-child {
  border-bottom: 0;
}

.pricing-block .btn-box {
  position: relative;
  text-align: center;
  margin-top: 10%;
}

.pricing-block .btn-box a {
  color: #fff !important;
}

.capacity li {
  font-size: 1.2em !important;
  padding: 10px 0px;
  color: #ff4a4a !important;
  font-weight: 700 !important;
}

.included {
  color: #ffd700;
}

.btn_buy {
  color: white;
  text-align: center;
}

.avaib{
  color: #ff4a4a;
}

.enaib{
  color:red;
}

.include{
  color: gold;
}

#gc-des {
  font-size: 13px;
}

</style>
